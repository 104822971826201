import React, { Fragment } from 'react';
import NoImg from '../images/noimg.png';
import PropTypes from 'prop-types';
//MUI
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    ...theme.spreadThis,
    card: {
        positiion: 'relative',
        display: 'flex',
        margin: 'auto 10px 10px 10px'
    },
    cardContent: {
        width: '100%',
        flexDirection: 'column',
        padding: 15
    },
    cover: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        objectFit: 'cover',
        position: 'relative',
        top: 15
    },
    name: {
        width: 65,
        height: 23,
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
        left: 60,
        bottom: 25
    },
    title: {
        width: 150,
        height: 20,
        backgroundColor: 'rgba(0,0,0,0.75)',
        marginTop: 5,
        marginBottom: 10
    },
    date: {
        width: 100,
        height: 14,
        backgroundColor: 'rgba(0,0,0,0.3)',
        marginBottom: 10
    },
    fullLine: {
        width: '90%',
        height: 15,
        backgroundColor: 'rgba(0,0,0,0.5)',
        marginBottom: 10
    },
    halfLine: {
        width: '50%',
        height: 15,
        backgroundColor: 'rgba(0,0,0,0.5)',
        marginBottom: 10
    }
})

const StorySkeleton = (props) => {
    const { classes } = props;

    const content = Array.from({ length: 5 }).map((item, index) => (
        <Card className={classes.card} key={index}>

            <CardContent className={classes.cardContent}>
                <img src={NoImg} className={classes.cover}/>
                <div className={classes.name}/>
                <div className={classes.title}/>
                <div className={classes.date}/>
                <div className={classes.fullLine}/>
                <div className={classes.fullLine}/>
                <div className={classes.halfLine}/>
            </CardContent>
        </Card>
    ))
    return <Fragment>{content}</Fragment>
}

StorySkeleton.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StorySkeleton);