import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import WebLogo from '../images/logo.png';
import { Link } from 'react-router-dom';

//MUI
import { Grid, Typography, TextField, Button, LinearProgress } from '@material-ui/core';

//redux
import { connect } from 'react-redux';
import { signupUser } from '../redux/actions/userActions';

const styles = (theme) => ({
    ...theme.spreadThis
})

class signup extends Component {
    constructor(){
        super();
        this.state = {
            email: '',
            password: '',
            confirmpassword: '',
            name: '',
            errors: {}
        }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.UI.errors){
            this.setState({ errors: nextProps.UI.errors });
        };
    };
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        })
        const newUserData = {
            email: this.state.email,
            password: this.state.password,
            confirmpassword: this.state.confirmpassword,
            name: this.state.name
        };
        this.props.signupUser(newUserData, this.props.history);
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    render() {
        const { classes, UI: { loading } } = this.props;
        const { errors } = this.state;
        return (
            <Grid container spacing={12} className={classes.form}>
                <Grid item sm/>
                <Grid item sm={6} xs={12}>
                    <img src={WebLogo} alt="pen image" className={classes.image}/>
                    <Typography variant="h3" className={classes.pageTitle}>
                        Nice to Meet You!
                    </Typography>
                    <form noValidate onSubmit={this.handleSubmit}>
                        <TextField id="email" name="email" type="email" label="Email" className={classes.textField}
                            helperText={errors.email} error={errors.email ? true : false} value={this.state.email} onChange={this.handleChange} fullWidth/>
                        <TextField id="password" name="password" type="password" label="Password" className={classes.textField}
                            helperText={errors.password} error={errors.password ? true : false} value={this.state.password} onChange={this.handleChange} fullWidth/>
                        <TextField id="confrimpassword" name="confirmpassword" type="password" label="Confirm Password" className={classes.textField}
                            helperText={errors.confirmpassword} error={errors.confirmpassword ? true : false} value={this.state.confirmpassword} onChange={this.handleChange} fullWidth/>
                        <TextField id="name" name="name" type="text" label="Nickname/User Tag" className={classes.textField}
                            helperText={errors.name} error={errors.name ? true : false} value={this.state.name} onChange={this.handleChange} fullWidth/>
                        <br/><br/>
                        {errors.general && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors.general}
                            </Typography>
                        )}
                        <Button type="submit" variant="contained" color="primary" className={classes.Button} disabled={loading}>
                            Sign up!
                            {loading && (
                                <LinearProgress size={30} className={classes.progress}/>
                            )}
                        </Button>
                        <br/><br/>
                        <small>Already a partner? Log in <Link to="/login" style={{color: '#f88f0f'}}>here</Link>!</small>
                    </form>
                </Grid>
                <Grid item sm/>
            </Grid>
        );
    }
}

signup.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired,
    signupUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user,
    UI: state.UI
})

export default connect(mapStateToProps, { signupUser })(withStyles(styles)(signup));
