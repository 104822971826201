export default {
  palette: {
    primary: {
      light: '#f9a53f',
      main: '#f88f0f',
      dark: '#ad640a',
      contrastText: '#fff'
    },
    secondary: {
      light: '#a53f3f',
      main: '#8f0f0f',
      dark: '#640a0a',
      contrastText: '#fff'
    }
  },
  spreadThis: {
    typography: {
      useNextVariants: true
    },
    form: {
      textAlign: 'center'
    },
    image: {
      maxWidth: 100,
      margin: 'auto auto auto auto'
    },
    pageTitle: {
      margin: '5px auto 5px auto'
    },
    textField: {
      margin: 'auto auto auto auto'
    },
    button: {
      margin: '5px auto 5px auto',
      textTransform: 'none'
    },
    customError: {
      color: '#ff0000',
      fontSize: '0.8rem',
      marginTop: 5
    },
    progress: {
      position: 'absolute'
    },
    invisibleSeparator: {
      border: 'none',
      margin: 4
    },
    visibleSeparator: {
      width: '100%',
      borderBottom: '1px solid rgba(0,0,0,.25)',
      marginBottom: 20
    },
    paper: {
      padding: 10
    },
    profile: {
      '& .image-wrapper': {
        textAlign: 'center',
        position: 'relative',
        '& button': {
          position: 'absolute',
          top: '80%',
          left: '70%'
        }
      },
      '& .profile-image': {
        width: 200,
        height: 200,
        objectFit: 'cover',
        maxWidth: '100%',
        borderRadius: '50%'
      },
      '& .profile-details': {
        textAlign: 'center',
        '& span, svg': {
          verticalAlign: 'middle'
        },
        '& a': {
          color: '#f88f0f'
        }
      },
      '& hr': {
        border: 'none',
        margin: '0 0 10px 0'
      },
      '& svg.button': {
        '&:hover': {
            cursor: 'pointer'
        }
      }
    },
    buttons: {
      textAlign: 'center',
      '& a': {
          margin: '20px 10px'
      }
    },
  }
}