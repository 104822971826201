import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonFramework from '../../util/ButtonFramework';
import PostStory from '../story/PostStory';
import Notifications from './Notifications';
//MUI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
//icons
import HomeIcon from '@material-ui/icons/Home';
import WebLogo from '../../images/logo.png';
import { Typography } from '@material-ui/core';

class Navbar extends Component {
    render() {
        const { authenticated } = this.props
        return (
            <AppBar>
                <Toolbar >
                <img src={WebLogo} alt="pen image" width='40px'/>
                <Typography variant="h6" style={{ flex: 1, marginLeft: 5 }}>
                    StoryBoard
                </Typography>
                { authenticated ? (
                    <Fragment>
                        <PostStory color="inherit"/>
                        <Link to="/">
                            <ButtonFramework tip="Home">
                                <HomeIcon color="inherit"/>
                            </ButtonFramework>
                        </Link>
                        <Notifications color="inherit"/>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button color="inherit" variant="text" component={Link} to="/login">Login</Button>
                        <Button color="inherit" variant="text" component={Link} to="/">Home</Button>
                        <Button color="inherit" variant="text" component={Link} to="/signup">Signup</Button>
                    </Fragment>
                )}
                </Toolbar>
            </AppBar>
        )
    }
}

Navbar.propTypes = {
    authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    authenticated: state.user.authenticated
})

export default connect(mapStateToProps)(Navbar)
