import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonFramework from '../../util/ButtonFramework';
import PostStory from '../story/PostStory';
import Notifications from './Notifications';
//MUI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Typography, GridList, ListItem } from '@material-ui/core';

import board from '../../pages/board';

class Subjects extends Component {
    render() {
        return (
            <GridList cellHeight={25} cols={1}>
                <ListItem >
                    <Button component={Link} to={`/ac`}>/ac</Button>
                    <Button component={Link} to={`/fr`}>/fr</Button>
                    <Button component={Link} to={`/hb`}>/hb</Button>
                    <Button component={Link} to={`/re`}>/re</Button>
                    <Button component={Link} to={`/sp`}>/sp</Button>
                    <Button component={Link} to={`/rand`}>/rand</Button>
                </ListItem>
            </GridList>
        )
    }
}

Subjects.propTypes = {
    authenticated: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    authenticated: state.user.authenticated
})

export default connect(mapStateToProps)(Subjects)
