import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Story from '../components/story/Story';
import StaticProfile from '../components/profile/StaticProfile';
import Grid from '@material-ui/core/Grid';

import StorySkeleton from '../util/StorySkeleton';
import ProfileSkeleton from '../util/ProfileSkeleton.js';

import { connect } from 'react-redux';
import { getUserData } from '../redux/actions/dataActions';


class user extends Component {
    state = {
        profile: null,
        storyIdParam: null
    }
    componentDidMount(){
        const name = this.props.match.params.name;
        const storyId = this.props.match.params.storyId;

        if(storyId) this.setState({ storyIdParam: storyId });

        this.props.getUserData(name);
        axios.get(`/user/${name}`)
            .then(res => {
                this.setState({
                    profile: res.data.user
                })
            })
            .catch(err => console.log(err));
    }
    render() {
        const { stories, loading } = this.props.data;
        const { storyIdParam } = this.state;

        const storiesMarkup = loading ? (
            <StorySkeleton/>
        ) : stories === null ? (
            <p>No stories from this user yet...</p>
        ) : !storyIdParam ? (
            stories.map(story => <Story key={story.storyId} story={story}/>)
        ) : (
            stories.map(story => {
                if(story.storyId !== storyIdParam)
                    return <Story key={story.storyId} story={story}/>
                else return <Story key={story.storyId} story={story} openDialog/>
            })
        )
        return (
            <Grid container spacing={12}>
                <Grid item sm={1} xs={12}>
                    AD.
                </Grid>
                <Grid item sm={3} xs={12}>
                    {this.state.profile === null ? (
                        <ProfileSkeleton/>
                    ) : (
                        <StaticProfile profile={this.state.profile}/>
                    )}
                </Grid>
                <Grid item sm={7} xs={12}>
                    {storiesMarkup}
                </Grid>
                <Grid item sm={1} xs={12}>
                    AD.
                </Grid>
            </Grid>
        )
    }
}

user.propTypes = {
    getUserData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    data: state.data
})

export default connect(mapStateToProps, {getUserData})(user);
