import { SET_STORIES, SET_STORY, LIKE_STORY, UNLIKE_STORY, LOADING_DATA, DELETE_STORY, POST_STORY, POST_COMMENT } from '../types';

const initialState = {
    stories: [],
    story: {},
    loading: false
};

export default function(state = initialState, action){
    switch(action.type){
        case LOADING_DATA:
            return {
                ...state,
                loading: true
            };
        case SET_STORIES:
            return {
                ...state,
                stories: action.payload,
                loading: false
            };
        case SET_STORY:
            return {
                ...state,
                story: action.payload
            };
        case LIKE_STORY:
        case UNLIKE_STORY:
            let index = state.stories.findIndex((story) => story.storyId === action.payload.storyId);
            state.stories[index] = action.payload;
            if (state.story.storyId === action.payload.storyId) {
                state.story = action.payload;
            }
            return {
                ...state
            };
        case DELETE_STORY:
            index = state.stories.findIndex(story => story.storyId === action.payload);
            state.stories.splice(index, 1);
            return {
                ...state
            };
        case POST_STORY:
            return {
                ...state,
                stories: [ action.payload, ...state.stories ]
            };
        case POST_COMMENT:
            return {
                ...state,
                story: {
                    ...state.story,
                    comments: [action.payload, ...state.story.comments]
                }
            };
        default:
            return state;
    }
}