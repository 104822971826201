import { SET_STORIES, LOADING_DATA, LIKE_STORY, UNLIKE_STORY, DELETE_STORY, SET_ERRORS, CLEAR_ERRORS, POST_STORY, LOADING_UI, STOP_LOADING_UI, SET_STORY, POST_COMMENT } from '../types';
import axios from 'axios';

//get all stories
export const getStories = () => dispatch => {
    dispatch({ type: LOADING_DATA });
    axios.get(`/stories`)
        .then(res => {
            dispatch({
                type: SET_STORIES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_STORIES,
                payload: []
            })
        })
};
//get specific subject stories
export const getSubjectStories = (board) => dispatch => {
    dispatch({ type: LOADING_DATA });
    axios.get(`${board}`)
        .then(res => {
            dispatch({
                type: SET_STORIES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: SET_STORIES,
                payload: []
            })
        })
};
//get one story
export const getStory = (storyId) => dispatch => {
    dispatch({ type: LOADING_UI });
    axios.get(`/story/${storyId}`)
        .then(res => {
            dispatch({
                type: SET_STORY,
                payload: res.data
            });
            dispatch({ type: STOP_LOADING_UI })
        })
        .catch(err => console.log(err));
}
//post a story
export const postStory = (newStory) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios.post(`/story`, newStory)
        .then(res => {
            dispatch({
                type: POST_STORY,
                payload: res.data
            });
            dispatch(clearErrors());
        })
        .catch(err => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data  
            })
        })
}
//like story
export const likeStory = (storyId) => dispatch => {
    axios.get(`/story/${storyId}/like`)
        .then(res => {
            dispatch({
                type: LIKE_STORY,
                payload: res.data
            });
        })
        .catch(err => console.log(err));
};
//unlike story
export const unlikeStory = (storyId) => dispatch => {
    axios.get(`/story/${storyId}/unlike`)
        .then(res => {
            dispatch({
                type: UNLIKE_STORY,
                payload: res.data
            });
        })
        .catch(err => console.log(err));
};
//delete story
export const deleteStory = (storyId) => (dispatch) => {
    axios.delete(`/story/${storyId}`)
        .then(() => {
            dispatch({ type: DELETE_STORY, payload: storyId })
        })
        .catch(err => console.log(err));
};
//post comment
export const postComment = (storyId, commentData) => (dispatch) => {
    axios.post(`/story/${storyId}/comment`, commentData)
        .then(res => {
            dispatch({
                type: POST_COMMENT,
                payload: res.data
            });
            dispatch(clearErrors());
        })
        .catch((err) => {
            dispatch({
                type: SET_ERRORS,
                payload: err.response.data
            });
        });
};

export const getUserData = (userName) => dispatch => {
    dispatch({ type: LOADING_DATA });
    axios.get(`/user/${userName}`)
        .then(res => {
            dispatch({
                type: SET_STORIES,
                payload: res.data.stories
            });
        })
        .catch(err => {
            dispatch({
                type: SET_STORIES,
                payload: null
            });
        })
}

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}