import React, { Component } from 'react';
import ButtonFramework from '../../util/ButtonFramework';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

//icons
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
//redux
import { connect } from 'react-redux';
import { likeStory, unlikeStory } from '../../redux/actions/dataActions';


export class LikeButton extends Component {
    likedStory = () => {
        if (
            this.props.user.likes &&
            this.props.user.likes.find(
                (like) => like.storyId === this.props.storyId
            )
        )
            return true;
        else return false;
    };
    likeStory = () => {
        this.props.likeStory(this.props.storyId);
    }
    unlikeStory = () => {
        this.props.unlikeStory(this.props.storyId);
    }
    render() {
        const { authenticated } = this.props.user;
        const likeButton = !authenticated ? (
            <Link to="/login">
                <ButtonFramework tip="like this story">
                    <FavoriteBorder style={{color: '#f88'}}/>
                </ButtonFramework>
            </Link>
        ) : this.likedStory() ? (
            <ButtonFramework tip="unlike this story" onClick={this.unlikeStory}>
                <FavoriteIcon style={{color: '#f88'}}/>
            </ButtonFramework>
        ) : (
            <ButtonFramework tip="like this story" onClick={this.likeStory}>
                <FavoriteBorder style={{color: '#f88'}}/>
            </ButtonFramework>
        );
        return likeButton
    }
}

LikeButton.propTypes = {
    user: PropTypes.object.isRequired,
    storyId: PropTypes.string.isRequired,
    likeStory: PropTypes.func.isRequired,
    unlikeStory: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapActionsToProps = {
    likeStory,
    unlikeStory
}

export default connect(mapStateToProps, mapActionsToProps)(LikeButton);
