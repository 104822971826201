import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import jwtDecode from 'jwt-decode';

//redux
import { Provider } from 'react-redux';
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { logoutUser, getUserData } from './redux/actions/userActions';

//components
import Navbar from './components/layout/Navbar';
import Subjects from './components/layout/Subjects';
import themeFile from './util/theme';
import AuthRoute from './util/AuthRoute';

//pages
import home from './pages/home';
import login from './pages/login';
import signup from './pages/signup';
import user from './pages/user';
import board from './pages/board';

import axios from 'axios';

const theme = createMuiTheme(themeFile);

axios.defaults.baseURL = 'https://us-east1-storyboard-36a89.cloudfunctions.net/api';

const token = localStorage.DBIdToken;
if(token){
  const decodedToken = jwtDecode(token);
  if(decodedToken.exp*1000 < Date.now()){
    store.dispatch(logoutUser())
    window.location.href = '/login'
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Navbar/>
          <div className="container">
          <Subjects/>
          <br/>
            <Switch>
              <Route exact path="/" component={home}/>
              <AuthRoute exact path="/login" component={login}/>
              <AuthRoute exact path="/signup" component={signup}/>
              <Route exact path="/user/:name" component={user}/>
              <Route exact path="/user/:name/story/:storyId" component={user}/>
              
              <Route exact path="/ac" component={board}/>
              <Route exact path="/fr" component={board}/>
              <Route exact path="/hb" component={board}/>
              <Route exact path="/re" component={board}/>
              <Route exact path="/sp" component={board}/>
              <Route exact path="/rand" component={board}/>
            </Switch>
          </div>
        </Router>
      </Provider>
        
      </MuiThemeProvider>
    );
  }
}

export default App;
