import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ButtonFramework from '../../util/ButtonFramework';
//MUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import GridList from '@material-ui/core/GridList';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
//redux
import { connect } from 'react-redux';
import { postStory, clearErrors } from '../../redux/actions/dataActions';

const styles = theme => ({
    ...theme.spreadThis,
    submitButton: {
        position: 'relative',
        float: 'right',
        marginTop: 10
    },
    progressSpinner: {
        position: 'absolute'
    },
    closeButton: {
        position: 'absolute',
        left: '91%',
        top: '6%'
    }
});

class PostStory extends Component{
    board = '';
    state = {
        open: false,
        title: '',
        subject: '',
        body: '',
        errors: {}
    };
    componentWillReceiveProps(nextProps) {
        if(nextProps.UI.errors){
            this.setState({
                errors: nextProps.UI.errors
            });
        };
        if(!nextProps.UI.errors && !nextProps.UI.loading){
            this.setState({ title: '', subject: '', body: '', open: false, errors: {} })
        }
    }
    handleOpen = () => {
        this.setState({ open: true })
    };
    handleClose = () => {
        this.props.clearErrors();
        this.setState({ open: false, errors: {} })
    };
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        console.log(this.board)
    };
    handleSubmit = (event) => {
        event.preventDefault();
        this.props.postStory({ title: this.state.title, subject: this.state.subject, body: this.state.body });
    };
    handle_ac = (event) => {event.preventDefault();this.setState({ subject: '/ac' });};
    handle_fr = (event) => {event.preventDefault();this.setState({ subject: '/fr' });};
    handle_hb = (event) => {event.preventDefault();this.setState({ subject: '/hb' });};
    handle_re = (event) => {event.preventDefault();this.setState({ subject: '/re' });};
    handle_sp = (event) => {event.preventDefault();this.setState({ subject: '/sp' });};
    handle_rand = (event) => {event.preventDefault();this.setState({ subject: '/rand' });};
    
    render(){
        const { errors } = this.state;
        const { classes, UI: { loading }} = this.props;
        return (
            <Fragment>
                <ButtonFramework onClick={this.handleOpen} tip="Share Your Story!">
                    <AddIcon/>
                </ButtonFramework>
                <Dialog
                open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="sm">
                    <ButtonFramework tip="close" onClick={this.handleClose} tipClassName={classes.closeButton}>
                        <CloseIcon/>
                    </ButtonFramework>
                    <DialogTitle>Share your story with the world.</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.handleSubmit}>
                            <TextField
                                name="title"
                                type="text"
                                label="Share"
                                multiline
                                rows="1"
                                placeholder="This is my story..."
                                error={errors.body ? true : false}
                                helperText={errors.body}
                                className={classes.textField}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            {this.state.subject}
                            <GridList cellHeight={20}>
                                <Button style={{textTransform: 'none'}} onClick={this.handle_ac}>Academics</Button>
                                <Button style={{textTransform: 'none'}} onClick={this.handle_fr}>Friendships</Button>
                                <Button style={{textTransform: 'none'}} onClick={this.handle_hb}>Hobbies</Button>
                                <Button style={{textTransform: 'none'}} onClick={this.handle_re}>Relationships</Button>
                                <Button style={{textTransform: 'none'}} onClick={this.handle_sp}>Sports</Button>
                                <Button style={{textTransform: 'none'}} onClick={this.handle_rand}>Random</Button>
                            </GridList>
                            <TextField
                                name="body"
                                type="text"
                                label="Share"
                                multiline
                                rows="3"
                                placeholder="This is my story..."
                                error={errors.body ? true : false}
                                helperText={errors.body}
                                className={classes.textField}
                                onChange={this.handleChange}
                                fullWidth
                            />
                            <Button type="submit" variant="contained" color="primary"
                                className={classes.submitButton} disabled={loading}>
                                Submit
                                {loading && (
                                    <CircularProgress size={30} className={classes.progressSpinner}/>
                                )}
                            </Button>
                        </form>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

PostStory.propTypes = {
    postStory: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    UI: state.UI
});

export default connect(mapStateToProps, { postStory, clearErrors })(withStyles(styles)(PostStory));