import React, { Component, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import ButtonFramework from '../../util/ButtonFramework';

//MUI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import { connect } from 'react-redux';
import { deleteStory } from '../../redux/actions/dataActions';

const styles = {
    deleteButton: {
        position: 'relative',
        left: 10,
        top: -2.5
    }
}

class DeleteStory extends Component {
    state = {
        open: false
    };
    handleOpen = () => {
        this.setState({ open: true });
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    deleteStory = () => {
        this.props.deleteStory(this.props.storyId);
        this.setState({ open: false });
    };
    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <ButtonFramework tip="delete story" onClick={this.handleOpen} btnClassName={classes.deleteButton}>
                    <DeleteOutline color="secondary"/>
                </ButtonFramework>
                <Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="sm">
                    <DialogTitle>
                        Are you sure you want to delete this story?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.deleteStory} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

DeleteStory.propTypes = {
    deleteStory: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    storyId: PropTypes.string.isRequired
}

export default connect(null, { deleteStory })(withStyles(styles)(DeleteStory));
