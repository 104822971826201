//user reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
//UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
//data reducer types
export const SET_STORIES = 'SET_STORIES';
export const SET_STORY = 'SET_STORY';
export const LIKE_STORY = 'LIKE_STORY';
export const UNLIKE_STORY = 'UNLIKE_STORY';
export const DELETE_STORY = 'DELETE_STORY';
export const POST_STORY = 'POST_STORY';
export const POST_COMMENT = 'POST_COMMENT';