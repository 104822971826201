import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ButtonFramework from '../../util/ButtonFramework';
import LikeButton from './LikeButton';
import Comments from './Comments';
import CommentForm from './CommentForm';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
//MUI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//icons
import CloseIcon from '@material-ui/icons/Close';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import ChatIcon from '@material-ui/icons/Chat';
//redux
import { connect } from 'react-redux';
import { getStory, clearErrors } from '../../redux/actions/dataActions';

const styles = theme => ({
    ...theme.spreadThis,
    profileImage: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        objectFit: 'cover',
        position: 'relative',
        top: 15
    },
    dialogContent: {
        padding: 20
    },
    closeButton: {
        position: 'absolute',
        left: '90%'
    },
    expandButton: {
        position: 'relative',
        left: 10
    },
    spinnerDiv: {
        textAlign: 'center',
        marginTop: 50,
        marginBottom: 50
    }
});

class StoryDialog extends Component{
    state = {
        open: false,
        oldPath: '',
        newPath: ''
    };
    componentDidMount() {
        if(this.props.openDialog){
            this.handleOpen();
        }
    }
    handleOpen = () => {
        let oldPath = window.location.pathname;

        const { userName, storyId } = this.props;
        const newPath = `/users/${userName}/story/${storyId}`;

        if(oldPath === newPath) oldPath = `/users/${userName}`;
        
        window.history.pushState(null, null, newPath);

        this.setState({ open: true, oldPath, newPath });
        this.props.getStory(this.props.storyId);
    }
    handleClose = () => {
        window.history.pushState(null, null, this.state.oldPath);
        this.setState({ open: false });
        this.props.clearErrors();
    }

    render(){
        const {
            classes,
            story: {
                storyId,
                title,
                subject,
                body,
                date,
                likeCount,
                commentCount,
                userImage,
                userName,
                comments
            },
            UI: { loading }
        } = this.props;

        const dialogMarkup = loading ? (
            <div className={classes.spinnerDiv}>
                <CircularProgress size={200} thickness={2}/>
            </div>
        ) : (
            <Grid container spacing={16}>
                <Grid item sm={16}>
                <img src={userImage} alt="Profile" className={classes.profileImage}/>
                    <Typography
                        component={Link}
                        color="primary"
                        variant="h5"
                        to={`/user/${userName}`}
                    >
                        {` `}@{userName}
                    </Typography>
                    <hr className={classes.invisibleSeparator}/>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        {dayjs(date).format('h:mm a, MMM DD YYYY')}
                    </Typography>
                    <hr className={classes.invisibleSeparator}/>
                    <Typography variant="body1">
                        {body}
                    </Typography>
                    <LikeButton storyId={storyId}/>
                    <span>{likeCount} likes</span>
                    <ButtonFramework tip="comments">
                        <ChatIcon  style={{color: '#88f'}}/>
                    </ButtonFramework>
                    <span>{commentCount} comments</span>
                </Grid>
                <hr className={classes.visibleSeparator}/>
                <CommentForm storyId={storyId}/>
                <Comments comments={comments}/>
            </Grid>
        );
        return (
            <Fragment>
                <ButtonFramework onClick={this.handleOpen} tip="expand story" tipClassName={classes.expandButton}>
                    <UnfoldMore color="primary"/>
                </ButtonFramework>
                <Dialog
                    open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="sm">
                    <ButtonFramework tip="close" onClick={this.handleClose} tipClassName={classes.closeButton}>
                        <CloseIcon/>
                    </ButtonFramework>
                    <DialogContent className={classes.dialogContent}>
                        {dialogMarkup}
                    </DialogContent>
                </Dialog>
            </Fragment>
        )
    }
}

StoryDialog.propTypes = {
    clearErrors: PropTypes.func.isRequired,
    getStory: PropTypes.func.isRequired,
    storyId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    story: PropTypes.object.isRequired,
    UI: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    story: state.data.story,
    UI: state.UI
})

const mapActionsToProps = {
    getStory,
    clearErrors
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(StoryDialog));