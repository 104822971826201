import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';
import ButtonFramework from '../../util/ButtonFramework';
import DeleteStory from './DeleteStory';
import StoryDialog from './StoryDialog';
import LikeButton from './LikeButton';

//MUI stuff
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';

//icons
import ChatIcon from '@material-ui/icons/Chat';

//redux
import { connect } from 'react-redux';

const styles = {
    card: {
        positiion: 'relative',
        display: 'flex',
        margin: 'auto 10px 10px 10px'
    },
    profileImage: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        objectFit: 'cover',
        position: 'relative',
        top: 15
    },
    content: {
        paddingHorizontal: 15,
        objectFit: 'cover',
        justifyContent: 'center'
    }
};

class Story extends Component {
    render() {
        dayjs.extend(relativeTime)
        const {
            classes, 
            story: {
                title,
                subject, 
                body,
                date,
                userImage,
                userName,
                storyId,
                likeCount,
                commentCount
            },
            user: { authenticated, credentials: { name } }
        } = this.props;
        
        const deleteButton = authenticated && userName === name ? (
            <DeleteStory storyId={storyId}/>
        ) : null

        return (
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <img src={userImage} alt="ProfileImage" className={classes.profileImage}/>
                    <Typography variant="h5" component={Link} to={`/user/${userName}`} color="primary">  @{userName}</Typography>
                    <br/>
                    <br/>
                    {deleteButton}
                    <Typography variant="h6">{title}
                        <Typography variant="subtitle1" component={Link} to={`${subject}`} style={{color: '#888'}}><small>{subject}</small></Typography>
                    </Typography> 
                    <Typography variant="body2" color="textSecondary">
                        {dayjs(date).fromNow()}
                    </Typography>
                    <Typography variant="body1">{body}</Typography>
                    <br/>
                    <LikeButton storyId={storyId}/>
                    <span>{likeCount} likes</span>
                    <ButtonFramework tip="comments">
                        <ChatIcon style={{color: '#88f'}}/>
                    </ButtonFramework>
                    <span>{commentCount} comments</span>
                    <StoryDialog storyId={storyId} userName={userName} openDialog={this.props.openDialog}/>
                </CardContent>
            </Card>
        );
    }
}

Story.propTypes = {
    user: PropTypes.object.isRequired,
    story: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    openDialog: PropTypes.bool
}

const mapStateToProps = state => ({
    user: state.user
})


export default connect(mapStateToProps)(withStyles(styles)(Story));
