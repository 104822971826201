import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import Story from '../components/story/Story';
import Profile from '../components/profile/Profile';
import StorySkeleton from '../util/StorySkeleton';

import { connect } from 'react-redux';
import { getStories } from '../redux/actions/dataActions';

class home extends Component {
    componentDidMount() {
        this.props.getStories();
    }
    render() {
        const { stories, loading } = this.props.data
        let recentStoriesMarkup = !loading ? (
            stories.map((story) => <Story key={story.storyId} story={story}/>)
        ) : (
            <StorySkeleton/>
        );
        return (
            <Grid container spacing={12}>
                <Grid item sm={1} xs={12}>
                    AD.
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Profile/>
                </Grid>
                <Grid item sm={7} xs={12}>
                    {recentStoriesMarkup}
                </Grid>
                <Grid item sm={1} xs={12}>
                    AD.
                </Grid>
            </Grid>
        );
    }
}

home.propTypes = {
    getStories: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    data: state.data
})

export default connect(mapStateToProps, { getStories })(home);
