import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Story from '../components/story/Story';
import StaticProfile from '../components/profile/StaticProfile';
import Grid from '@material-ui/core/Grid';

import StorySkeleton from '../util/StorySkeleton';
import ProfileSkeleton from '../util/ProfileSkeleton.js';

import { connect } from 'react-redux';
import { getSubjectStories } from '../redux/actions/dataActions';
import { Typography } from '@material-ui/core';

class board extends Component {
    state = {
        profile: null,
        storyIdParam: null,
    }
    componentDidMount(){
        const board = this.props.location.pathname;
        const storyId = this.props.match.params.storyId;

        if(storyId) this.setState({ storyIdParam: storyId });

        this.props.getSubjectStories(board);
    }
    render() {
        const { stories, loading } = this.props.data;
        const { storyIdParam } = this.state;

        const storiesMarkup = loading ? (
            <StorySkeleton/>
        ) : stories === null ? (
            <p>No stories for this board yet...</p>
        ) : !storyIdParam ? (
            stories.map(story => <Story key={story.storyId} story={story}/>)
        ) : (
            stories.map(story => {
                if(story.storyId !== storyIdParam)
                    return <Story key={story.storyId} story={story}/>
                else return <Story key={story.storyId} story={story} openDialog/>
            })
        )
        return (
            <Grid container spacing={12}>
                <Grid item sm={1} xs={12}>
                    AD.
                </Grid>
                <Grid item sm={10} xs={12}>
                    <Typography variant={'h5'} align={'center'}>
                    {this.props.location.pathname}
                    </Typography>
                    <hr/>
                    {storiesMarkup}
                </Grid>
                <Grid item sm={1} xs={12}>
                    AD.
                </Grid>
            </Grid>
        )
    }
}

board.propTypes = {
    getUserData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    data: state.data
})

export default connect(mapStateToProps, {getSubjectStories})(board);
